import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import throttle from 'lodash.throttle';
import { Link } from 'react-scroll';

import { fadeInAnimation } from '~styles/animations';

const navMobile = css`
    top: 0;
    left: ${props => props.open ? '0' : '-110%'};
    width: 80%;
    height: 100%;
    z-index: 20;
    display: block;
    position: fixed;
    padding: 3rem;
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.font};
    transition: left .3s ease;
`;

const navDesktop = css`
    display: flex;
    flex-direction: ${props => props.direction};
    line-height: 3.3rem;
`;

const NavItem = styled(Link)`
  display: block;
  color: inherit;
  overflow: hidden;
  transition: opacity .1s linear;
  opacity: .4;

  &.active {
    opacity: 1;
  }
`;

const Nav = styled.nav.attrs(props => ({
  open: props.open
}))`
  font-size: 2.5em;
  font-weight: 300;
  line-height: 3rem;
  text-transform: uppercase;
 
  @media screen and (max-width: ${props => props.theme.devices.md}) {
    ${navMobile}
    ${NavItem} {
      padding: 3rem 0;
      border-bottom: 1px solid ${props => props.theme.colors.border};
    }
  }

  @media screen and (min-width: ${props => props.theme.devices.md}) {
    ${navDesktop}
    ${NavItem} {
      padding: ${props => props.direction === 'column' ? '.4rem 0' : '.7rem'};
    }
  }

  @media screen and (min-width: ${props => props.theme.devices.xl}) {
    font-size: 3em;
    line-height: 4rem;
  }
`;

const Hamburger = styled.button`
  cursor: pointer;
  position: fixed;
  left: 1.5rem;
  top: 1.5rem;
  border: 0;
  background: none;
  color: inherit;

  @media screen and (min-width: ${props => props.theme.devices.md}) {
    display: none;
  }
`

const HamburgerLabel = styled.span`
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  position: absolute;
  clip: rect(0,0,0,0);
  border: 0;
`

const HamburgerIcon = styled.div`
fill: currentColor;
`

const Overlay = styled.div`
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: fixed;
    background-color: rgba(0,0,0,.6);
    animation-name: ${fadeInAnimation};
    animation-duration: .2s; 
    animation-timing-function: ease-out; 
`

const Navigation = ({ items, direction }) => {

  const [open, setOpen] = useState(false);

  const openNav = () => {
    if (!open) {
      setOpen(true);
    }
  };

  const closeNav = () => {
    if (open) {
      setOpen(false);
    }
  };

  useEffect(() => {
    const cleanup = () => {
      window.removeEventListener('scroll', handleClose, false);
      window.removeEventListener('resize', handleClose, false);
    };

    const handleClose = throttle(() => {
      if (open) {
        cleanup();
        setOpen(false);
      }
    }, 1000);

    if (open) {
      cleanup();
      window.addEventListener('scroll', handleClose, false);
      window.addEventListener('resize', handleClose, false);
    }

    return cleanup;
  }, [open]);

  return (
    <React.Fragment>
      <Hamburger onClick={openNav} title="Menu">
        <HamburgerLabel>Open Menu</HamburgerLabel>
        <HamburgerIcon aria-hidden="true">
          <svg xmlns="http://www.w3.org/2000/svg" height="50" viewBox="0 0 24 24" width="50"><path d="M0 0h24v24H0z" fill="none" /><path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" /></svg>
        </HamburgerIcon>
      </Hamburger>
      <Nav open={open} direction={direction}>
        {items.map((item) =>
          <NavItem key={item.location} href={`#${item.location}`} title={item.title} to={item.location} smooth="easeInOutQuart" spy={true} onClick={closeNav}>
            {item.label}
          </NavItem>
        )}
      </Nav>
      {open ?
        <Overlay onClick={closeNav} aria-hidden="true"></Overlay> : ''
      }
    </React.Fragment>
  );
}

Navigation.defaultProps = {
  direction: 'column',
  items: []
};

Navigation.propTypes = {
  layout: PropTypes.oneOf([
    'row',
    'column'
  ]),
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
      active: PropTypes.bool
    })
  )
};

export default Navigation;
