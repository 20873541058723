import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Element = styled.strong`
  width: 100%;
  display: block;
  border-radius: .2rem;
  padding: 1rem 2rem;
  text-align: center;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 2.5rem;
    height: 2.5em;
    margin-right: .5rem;
  }
`;

const Notice = ({ children, type }) => {
  return (
    <Element type={type}>
      {type === 'error' ?
        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" fill="rgb(146 0 5)"><path d="M0 0h24v24H0z" fill="none" /><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" /></svg>
        : ''}
      {children}
    </Element>
  )
};

Notice.defaultProps = {
  type: 'notice'
};

Notice.propTypes = {
  type: PropTypes.oneOf([
    'notice',
    'error'
  ])
};

export default Notice;
