import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Element = styled.div`
  flex: 1 1 auto;
  flex-wrap: wrap;
  align-self: ${props => props.align || 'auto'};
  padding: ${props => props.gutters ? `${props.gutters} 0 0 ${props.gutters}` : '0'};
  ${props => getMediaQuery(props, 'xs')};
  ${props => getMediaQuery(props, 'sm')};
  ${props => getMediaQuery(props, 'md')};
  ${props => getMediaQuery(props, 'lg')};
  ${props => getMediaQuery(props, 'xl')};
  ${props => getMediaQuery(props, 'xxl')};
`;

const Col = ({ children, as, className, ...props }) => {
  return (
    <Element as={as} className={className}{...props}>
      {children}
    </Element>
  )
};

/**
 * @param {Object} props 
 * @param {String} size 
 */
const getMediaQuery = (props, size) => {

  const span = props[size];
  const offset = props[`${size}Offset`];
  const order = props[`${size}Order`];
  const minWidth = props.theme.devices[size];

  if (!span && !offset && !order) {
    return '';
  }

  return (`@media screen and (min-width: ${minWidth}) {
    ${span ? `flex: 0 0 ${(span / 12) * 100}%;` : ''};
    ${offset ? `margin-left: ${(offset / 12) * 100}%;` : ''};
    ${order ? `order: ${order};` : ''};
  }`);
};

Col.defaultProps = {
  gutters: '3rem',
  as: 'div'
};

Col.propTypes = {
  gutters: PropTypes.string,
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  xxl: PropTypes.number,
  xsOffset: PropTypes.number,
  smOffset: PropTypes.number,
  mdOffset: PropTypes.number,
  lgOffset: PropTypes.number,
  xlOffset: PropTypes.number,
  xxlOffset: PropTypes.number,
  xsOrder: PropTypes.number,
  smOrder: PropTypes.number,
  mdOrder: PropTypes.number,
  lgOrder: PropTypes.number,
  xlOrder: PropTypes.number,
  xxlOrder: PropTypes.number,
  align: PropTypes.oneOf([
    'start',
    'end',
    'center',
    'stretch',
    'flex-start',
    'flex-end'
  ])
};

export default Col;
