import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { contentLayoutMixin } from '~styles/mixins';
import { Row, Col } from '~components/Grid/Grid';
import Navigation from '~components/Navigation/Navigation';

const Header = styled.header`
  ${contentLayoutMixin};
  padding-top: 8rem;
  padding-bottom: 8rem;
  background: ${(props) => props.theme.colors.background};
  display: flex;
  align-items: center;

  @media screen and (max-width: ${(props) =>
      props.theme.devices.xxl}) and (orientation: landscape) {
    min-height: 100vh;
  }
`;

const H1 = styled.h1`
  font-size: 3.7rem;
  font-weight: 200;
  line-height: 5rem;
  text-transform: uppercase;
  margin-bottom: 1.2rem;

  span {
    font-size: 7rem;
    font-weight: 500;
    display: block;
  }

  @media screen and (max-width: 350px) {
    font-size: 3.7rem;
    line-height: 4.2rem;
    span {
      font-size: 5.5rem;
    }
  }
`;

const Tagline = styled.p`
  font-size: 2rem;
  font-family: inherit;
  font-weight: 300;
  text-indent: 0.3rem;
  letter-spacing: 0.62rem;
  text-transform: uppercase;
  margin-bottom: 0;

  @media screen and (max-width: 350px) {
    font-size: 1.7rem;
    line-height: 1.6rem;
    letter-spacing: 0.41rem;
    span {
      font-size: 5.5rem;
    }
  }
`;

const Paragraph = styled.p`
  margin-bottom: 0;

  a,
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: ${(props) => props.theme.colors.link};
    font-weight: 500;
  }

  @media screen and (min-width: ${(props) => props.theme.devices.lg}) {
    margin-top: 5rem;
  }
`;

const HeaderExpanded = ({ navigation }) => {
  const { section } = useStaticQuery(graphql`
    query {
      section: sectionYaml(title: { eq: "About" }) {
        ...AboutSection
      }
    }
  `);

  const { image, content } = section;

  return (
    <Header id="about">
      <Row align="flex-end">
        <Col xs={12} md={8}>
          <GatsbyImage
            image={getImage(image.src)}
            alt={image.alt}
            loading="eager"
          />
        </Col>
        <Col xs={12} md={4}>
          <Navigation items={navigation} direction="column" />
        </Col>
        <Col xs={12} lg={4}>
          <H1>
            Jon <span>Wenmoth</span>
          </H1>
          <Tagline>Software Developer</Tagline>
        </Col>
        {content.map((content, key) => (
          <Col
            as={Paragraph}
            key={key}
            xs={12}
            md={6}
            lg={4}
            align="flex-start"
          >
            {content}
          </Col>
        ))}
      </Row>
    </Header>
  );
};

HeaderExpanded.defaultProps = {
  navigation: [],
};

HeaderExpanded.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired,
    })
  ),
};

export default HeaderExpanded;
