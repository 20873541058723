import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { FormContext } from '~contexts/FormContextProvider';
import { Label } from './Fields';

const Wrapper = styled.div`
  font-size: ${props => props.theme.type.sizeForm || 'inherit'};
  position: relative;
  width: 100%;
  :not(:last-of-type) {
    margin-bottom: 2rem;
  }
`;

const ElementBlur = css`
  top: 0;
  opacity: 1;
  font-size: inherit;
  margin: 0 0 2rem 0;
  cursor: text;
`

const ElementFocus = css`
  top: -2.5rem;
  opacity:.6;
  font-size: 80%;
  margin: 1rem 0 0 0;
`

const Element = styled(Label)`
  position: absolute;
  transition: all .1s ease;
  ${props => props.focus ?
    ElementFocus :
    ElementBlur
  };
`;

const LabelFloating = ({ children, value }) => {

  const [focus, setFocus] = useState(false);
  const context = useContext(FormContext);

  const handleFocus = () => {
    if (!focus) {
      setFocus(true);
    }
  };

  const handleBlur = (event) => {
    if (focus) {
      setFocus(event.target.value ? true : false);
    }
  };

  useEffect(() => {
    if (context && context.current) {
      let form = context.current;
      const reset = () => {
        setFocus(false);
      };
      form.addEventListener('reset', reset, false);
      return () => {
        form.removeEventListener('reset', reset, false);
      }
    }
  }, [context]);

  return (
    <Wrapper onFocus={handleFocus} onBlur={handleBlur}>
      <Element value={value} focus={focus}>
        {children}
      </Element>
    </Wrapper>
  )
};

LabelFloating.defaultProps = {
  reset: false
};

LabelFloating.propTypes = {
  value: PropTypes.string.isRequired,
  reset: PropTypes.bool,
};

export default LabelFloating;
