import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inputMixin } from '~styles/mixins';

const Element = styled.input`
  ${inputMixin}
`;

const Input = ({ className, type, name, id, value, required }) => {
  return (
    <Element className={className} type={type} name={name} id={id} value={value} required={required} />
  )
};

Input.defaultProps = {
  type: 'text',
  required: false
};

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default Input;
