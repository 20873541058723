import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const Element = styled.div`
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => props.direction};
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};
  margin: ${props => props.gutters ? `-${props.gutters} 0 ${props.gutters} -${props.gutters}` : '0'};

  ${props => props.last && css`
    margin-bottom: 0;
  `};
`;

const Row = ({ children, ...props }) => {

  const cols = React.Children.map(children, child => {
    return React.cloneElement(child, {
      gutters: props.gutters
    })
  });

  return <Element {...props}>{cols}</Element>;
};

Row.defaultProps = {
  align: 'stretch',
  justify: 'flex-start',
  direction: 'row',
  as: 'div',
  gutters: '3rem',
  last: false
};

Row.propTypes = {
  align: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'stretch'
  ]),
  justify: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'space-between',
    'space-evenly',
    'middle'
  ]),
  direction: PropTypes.oneOf([
    'row',
    'column',
    'row-reverse',
    'column-reverse'
  ]),
  gutters: PropTypes.string,
  last: PropTypes.bool,
  className: PropTypes.string
};

export default Row;
