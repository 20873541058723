import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * @param {Array} path 
 * @param {Object} elevation 
 */
const fly = (path, elevation) => keyframes`
    0% {
     transform: translate(${path[0]}vw, ${elevation.cur}px);
    }
    33% {
      transform: translate(${path[1]}vw, ${elevation.max}px);
    }
    66% {
      transform: translate(${path[2]}vw, ${elevation.max}px);
    }
    90% {
      transform: translate(${path[3]}vw, ${elevation.min}px);
    }
    95% {
      transform: translate(${path[4]}vw ,${elevation.min - 2}px);
    }
    100% {
      transform: translate(${path[5]}vw, ${elevation.min}px);
    }
`
const Wrapper = styled.div`
  overflow: visible;
  ${props => props.border ?
    `border-bottom: 1px solid ${props.theme.colors.border || 'rgb(0,0,0)'};` : ''
  }
`

const Plane = styled.img.attrs(props => ({
  animating: props.flying || false,
  path: props.path || [],
  elevation: props.elevation || { min: 0, max: 0, cur: 0 },
  position: props.position || 0
}))`
  left: 0;
  display: block;
  height: inherit;
  position: absolute;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  animation-duration: ${props => props.duration}s;
  animation-name: ${props => fly(props.path, props.elevation)};
`;

const FlightPath = ({ className, destinations, destination, duration, runway, minimum }) => {

  const { PlaneEast, PlaneWest } = useStaticQuery(graphql`
    query {
      PlaneEast: file(
        relativePath: { eq: "plane-east.svg" }, 
        sourceInstanceName: { eq: "images" }
      ) {
        publicURL
      }
      PlaneWest: file(
        relativePath: { eq: "plane-west.svg" }, 
        sourceInstanceName: { eq: "images" }
      ) {
        publicURL
      }
    }
  `);

  const plane = useRef();
  const airport = useRef();

  const [flight, setFlight] = useState({ direction: 1, position: 0 });

  useEffect(() => {
    airport.current = destinations[destination];
  }, [destinations, destination]);

  useEffect(() => {
    if (plane.current && airport.current) {

      let flight = getFlight(
        plane.current,
        airport.current,
        destination,
        duration
      );

      setFlight((current) => {
        return canFly(current, flight, minimum) ? flight : current;
      });
    }
  }, [destination, duration, minimum]);

  return (
    <Wrapper className={className} border={runway} aria-hidden="true">
      <Plane src={flight.direction === -1 ? PlaneWest.publicURL : PlaneEast.publicURL} ref={plane} alt="Plane"{...flight} />
    </Wrapper>
  );
}

/**
 * @param {Element} plane 
 * @param {Element} airport 
 * @param {String} destination
 * @param {Number} duration
 * @return {Object}
 */
const getFlight = (plane, airport, destination, duration) => {

  let planeWidth = plane.offsetWidth,
    planeHeight = plane.offsetHeight,
    planeLeft = plane.getBoundingClientRect().left,
    planeTop = plane.getBoundingClientRect().top - plane.parentNode.getBoundingClientRect().top;

  let runway = airport.getBoundingClientRect().left + (airport.offsetWidth / 2);

  let to = Math.round(runway - (planeWidth / 2)),
    from = Math.round(planeLeft);

  let direction = (from < to ? 1 : -1);

  let path = getFlightPath(from, to, direction)
    .map((value) => {
      return (value / window.innerWidth) * 100;
    });

  return {
    to: to,
    from: from,
    path: path,
    position: from,
    duration: duration,
    direction: direction,
    destination: destination,
    elevation: {
      min: 0,
      max: -planeHeight,
      cur: planeTop,
    }
  };
};

/**
 * @param {Number} from
 * @param {Number} to
 * @param {Number} direction
 * @return {Array}
 */
const getFlightPath = (from, to, direction) => {
  let bounce = 10 * direction,
    step = Math.round(((to - from) - bounce) / 3);

  let path = [
    from,
    from + step,
    from + (step * 2),
    from + (step * 3),
    from + (step * 3) + (bounce / 2),
    to
  ];

  return path;
};

/**
 * @param {Object} current 
 * @param {Object} next 
 * @param {Number} minimum 
 * @return {Boolean}
 */
const canFly = (current, next, minimum) => {
  if (current.destination === next.destination) {
    return false;
  }
  return (Math.abs(next.to - next.from) > minimum);
}

FlightPath.defaultProps = {
  minimum: 50,
  duration: 3,
  runway: true
};

FlightPath.propTypes = {
  className: PropTypes.string,
  runway: PropTypes.bool,
  minimum: PropTypes.number,
  duration: PropTypes.number,
  destination: PropTypes.string,
  destinations: PropTypes.objectOf(
    PropTypes.object
  ).isRequired
}

export default FlightPath;
