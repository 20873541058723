import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div.attrs(props => ({
  margin: props.margin || 0,
  align: props.align || 'flex-start'
}))`
  display: flex;
  flex-wrap: nowrap;
  align-items: ${props => props.align};
`;

const Image = styled.img.attrs(props => ({
  width: props.width || 'auto',
  height: props.height || 'auto'
}))`
  margin-right: 1.5rem;
  width: ${props => props.width};
  height: ${props => props.height};
`;

const Content = styled.div`
  flex: 1 1 auto;
`;

const ImageCard = ({ children, as, className, align, image, imageAlt, imageWidth, imageHeight, imageRef }) => {
  return (
    <Wrapper className={className} as={as} align={align}>
      <Image ref={imageRef} src={image} alt={imageAlt} width={imageWidth} height={imageHeight} />
      <Content>
        {children}
      </Content>
    </Wrapper>
  )
};

ImageCard.defaultProps = {
  as: 'div',
  imageWidth: 'auto',
  imageHeight: 'auto',
  align: 'flex-start'
};

ImageCard.propTypes = {
  align: PropTypes.oneOf([
    'flex-start',
    'flex-end',
    'center',
    'stretch'
  ]),
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  imageRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func
  ])
};

export default ImageCard;
