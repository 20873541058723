
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Scroll, { Link } from 'react-scroll';
import useDebounce from '~hooks/useDebounce';

const Nav = styled.nav`
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${props => props.theme.colors.border};;
  min-height: 5rem;

  @media screen and (max-width: ${props => props.theme.devices.sm}) {
    display: none;
  }
`

const Button = styled(Link)`

  cursor: pointer;
  min-width: 4rem; 
  padding: 0.7rem;
  margin: 0 .1rem;
  position: relative;
  display: block;
  border-radius: .2rem;
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.buttonFont || 'rgb(255, 255, 255)'};
  background: ${props => props.theme.colors.button || 'rgb(0, 0, 0)'};
  border-color: ${props => props.theme.colors.button || 'rgb(0, 0, 0)'};

  @media screen and (min-width: ${props => props.theme.devices.md}) {
    min-width: 5rem; 
    font-size: 1.1rem;
  }

  &:before {
    top: 100%;
    left: 50%;
    width: .5rem;
    height: .5rem;
    font-size: 2rem;
    position: absolute;
    transform: translate(-50%, .75rem);
    border-radius: 50%;
    background: inherit;
    content: ' ';
  }

  &:after {
    top: 100%;
    left: 50%;
    height: 0;
    width: 0;
    border: solid transparent;
    position: absolute;
    transform: translateX(-50%);
    border-top-color: inherit;
    border-width: .3rem;
    content: ' ';
  }
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  transition: all .4s ease;
  flex: 1 1 auto;

  ${props => props.active && css`
    flex: 100 1 auto;
    ${Button} {
      background: ${props => props.theme.colors.highlight || 'rgb(0, 186, 164)'};
      border-color: ${props => props.theme.colors.highlight || 'rgb(0, 186, 164)'};
    }
  `};
`

const Timeline = ({ dates, date, offset, onChange }) => {

  const ref = useRef();
  const active = useRef(date);
  const isScrolling = useRef(false);

  useEffect(() => {
    if (active.current !== date) {
      active.current = date;
      Scroll.scroller.scrollTo(date, {
        smooth: 'easeInOutQuart',
        duration: 600,
        offset: (offset * -1)
      });
    }
  }, [date, offset]);

  useEffect(() => {
    Scroll.Events.scrollEvent.register('begin', () => { isScrolling.current = true; });
    Scroll.Events.scrollEvent.register('end', () => { isScrolling.current = false; });
    return () => {
      Scroll.Events.scrollEvent.remove('begin');
      Scroll.Events.scrollEvent.remove('end');
    };
  }, []);

  const handleChange = (key) => {
    if (key !== active.current) {
      active.current = key;
      if (typeof onChange === 'function') {
        onChange(key);
      }
    }
  };

  const handleSetActiveState = useDebounce((key) => {
    if (!isScrolling.current) {
      handleChange(key);
    }
  }, 500);

  return (
    <Nav ref={ref}>
      {dates.map((item, index) =>
        <Wrapper key={item.key} active={item.key === date}>
          <Button href={`#${item.key}`} title={(index === 0) ? item.to : item.from} to={item.key} spy={true} smooth="easeInOutQuart" duration={600} offset={(offset * -1)} onClick={() => handleChange(item.key)} onSetActive={handleSetActiveState}>
            {(index === 0) ? item.to : item.from}
          </Button>
        </Wrapper>
      )
      }
    </Nav>
  );
};

Timeline.defaultProps = {
  offset: 0
};

Timeline.propTypes = {
  onChange: PropTypes.func,
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  offset: PropTypes.number,
  dates: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      from: PropTypes.number.isRequired,
      to: PropTypes.number.isRequired
    }).isRequired
  ).isRequired
};

export default Timeline;