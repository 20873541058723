
/**
 * @param {Element} target 
 * @param {Element} self 
 * @return {Number}
 */
export const getElementOverlap = (root, target) => {
  if (!root || !target) {
    return 0;
  }

  const rHeight = root.offsetHeight;
  //const rTop = root.getBoundingClientRect().top; // There is a bug in mobile safari so can't use this for now
  const tTop = target.getBoundingClientRect().top;

  return Number((1 - (Math.round((tTop / (0 + rHeight)) * 10) / 10)).toFixed(1));
};
