
import React from 'react';
import Heading from './Heading';

const H3 = ({ children, ...props }) => {
  return (
    <Heading as="h3" {...props}>{children}</Heading>
  );
};

H3.defaultProps = {
  fontSize: '2.2rem',
  lineHeight: '2.5rem'
};

export default H3;