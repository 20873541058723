import Form from './Form';
import AsyncForm from './AsyncForm';
import Label from './Label';
import LabelFloating from './LabelFloating';
import Input from './Input';
import TextArea from './TextArea';
import Button from './Button';
import Notice from './Notice';

let fields;

/**
 * @return {Array}
 */
const getFields = () => {
  if (!fields) {
    fields = [
      { component: Input },
      { component: TextArea }
    ];
  }
  return fields;
};

/**
 * @param {Array} components 
 * @return {Object}
 */
const getFirstFieldProps = (components) => {

  for (var i = 0; i < components.length; i++) {
    let component = components[i];
    if (isField(component.type)) {
      return component.props;
    }
  }

  return {};
};

/**
 * @param {Object} type 
 * @return {Boolean}
 */
const isField = (type) => {
  return getFields()
    .filter((field) => type === field.component).length > 0;
};

export {
  Form,
  AsyncForm,
  Label,
  LabelFloating,
  Input,
  TextArea,
  Button,
  Notice,
  isField,
  getFields,
  getFirstFieldProps
};