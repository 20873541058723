import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div` 
  &[aria-hidden="true"] {
    visibility: hidden;
  }
`

const Placeholder = React.forwardRef((props, ref) => {

  const {
    children,
    invisible
  } = props;

  return (
    <Wrapper ref={ref} aria-hidden={invisible}>
      {children}
    </Wrapper>
  );
});

Placeholder.defaultProps = {
  invisible: false
};

Placeholder.propTypes = {
  invisible: PropTypes.bool
};

export default Placeholder;
