import React from 'react';
import styled from 'styled-components';

const Element = styled.footer`
  color: ${props => props.theme.colors.font || 'rgb(255,255,255)'};
  background: ${props => props.theme.colors.background || 'rgb(0,0,0)'};
  padding: 2rem;
  text-align: center;
  text-transform: uppercase;
  height: 6rem;
  width: 100%;
`

const Footer = ({ children }) => {
  return (
    <Element>{children}</Element>
  )
};

export default Footer;
