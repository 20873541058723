
import React from 'react';
import Heading from './Heading';

const H1 = ({ children, ...props }) => {
  return (
    <Heading as="h1"{...props}>{children}</Heading>
  );
};

H1.defaultProps = {
  fontSize: '3.5em',
  lineHeight: '4.2rem'
};

export default H1;