
import React from 'react';
import Heading from './Heading';

const H4 = ({ children, ...props }) => {
  return (
    <Heading as="h4" {...props}>{children}</Heading>
  );
};

H4.defaultProps = {
  fontSize: '1.4rem',
  lineHeight: '1.9rem'
};

export default H4;