import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GlobalStyles } from '~styles/GlobalStyles';
import { ThemesContext, ThemeContextProvider } from '~contexts/ThemeContextProvider';
import SEO from '~components/SEO/SEO';

import Header from './Header';
import Footer from './Footer';


const Layout = ({ children, transitions }) => {

  const data = useStaticQuery(graphql`
    query {
      navigation: allNaivgationYaml(sort: {fields: position, order: ASC}) {
        edges {
          node {
            title
            label
            location
          }
        }
      }
    }
  `);

  const navigation = data.navigation.edges
    .map(item => {
      return item.node;
    });

  const themes = useContext(ThemesContext);

  return (
    <React.Fragment>
      <SEO />
      <ThemeContextProvider name="default">
        <GlobalStyles />
        <Header navigation={navigation} transitions={transitions} themes={themes} />
        <main>
          {children}
        </main>
      </ThemeContextProvider>
      <ThemeContextProvider name="blue">
        <Footer>
          • Be rad •
      </Footer>
      </ThemeContextProvider>
    </React.Fragment>
  )
};

Layout.defaultProps = {
  transitions: []
};

Layout.propTypes = {
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.object.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  )
};

export default Layout;
