import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form from './Form';
import { getFormData } from '~helpers/form';

const AsyncForm = ({ children, encType, onSubmit, onSuccess, onFailure, ...props }) => {
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (typeof onSubmit === 'function') {
      onSubmit(event);
    }

    const form = event.target,
      action = form.getAttribute('action'),
      method = form.getAttribute('method') || 'POST';

    if (action) {
      fetch(action, {
        method: method,
        headers: {
          'Content-Type': encType,
        },
        body: getFormData(encType, form),
      })
        .then((response) => {
          response.status === 200 ? handleSuccess(response, form) : handleFailure(response);
        })
        .catch(handleFailure);

      setDisabled(true);
    }
  };

  const handleSuccess = (response, form) => {
    form.reset();
    setDisabled(false);
    if (typeof onSuccess === 'function') {
      onSuccess(response);
    }
  };

  const handleFailure = (response) => {
    setDisabled(false);
    if (typeof onFailure === 'function') {
      onFailure(response);
    }
  };

  return (
    <Form disabled={disabled} onSubmit={handleSubmit} {...props}>
      {children}
    </Form>
  );
};

AsyncForm.defaultProps = {
  encyType: 'application/json',
};

AsyncForm.propTypes = {
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  encType: PropTypes.oneOf([
    'application/x-www-form-urlencoded',
    'multipart/form-data',
    'text/plain',
    'application/json',
  ]),
};

export default AsyncForm;
