
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { H2 } from '~components/Heading/Headings';
import { Row, Col } from '~components/Grid/Grid';
import Section from './Section';

import {
  AsyncForm,
  LabelFloating,
  Input,
  TextArea,
  Button,
  Notice
} from '~components/Form/Fields';

const Wrapper = styled.div`
  color: ${props => props.theme.colors.font};
  background-color: ${props => props.theme.colors.background};
  padding: 8rem 0;
  min-height: calc(100vh - 6rem);
`

const HoneyPot = styled(Input)`
  display: none;
`

const Contact = ({ overlapRef }) => {

  const { section } = useStaticQuery(graphql`
    query {
      section: sectionYaml(title: {eq: "Contact"}) {
        title
        description
      }
    }
  `);

  const { title, description } = section;

  const [sending, setSending] = useState(false);
  const [notice, setNotice] = useState();

  const handleSubmit = () => {
    setSending(true);
    setNotice(null);
  };

  const handleSuccess = () => {
    showNotice('Thanks for getting in touch!', 'notice');
    setSending(false);
  };

  const handleFailure = () => {
    showNotice('Yeah Nah that didn\'t send...', 'error');
    setSending(false);
  };

  const showNotice = (message, type) => {
    setNotice({
      type: type,
      message: message,
    });
  };

  return (
    <Wrapper ref={overlapRef} id="contact">
      <Section>
        <Row>
          <Col xs={12} md={5} lg={4}>
            <H2>{title}</H2>
            <p>{description}</p>
          </Col>
          <Col as={AsyncForm} xs={12} md={7} lg={7} lgOffset={1}
            action="https://formspree.io/f/xeqpyqaw"
            name="Contact" method="POST"
            encType="application/json"
            onSubmit={handleSubmit}
            onSuccess={handleSuccess}
            onFailure={handleFailure}
          >
            <LabelFloating value="Name">
              <Input name="name" required={true} />
            </LabelFloating>
            <LabelFloating value="Email">
              <Input type="email" name="email" required={true} rules={['email']} />
            </LabelFloating>
            <LabelFloating value="Message">
              <TextArea name="message" required={true} />
            </LabelFloating>
            <HoneyPot name="_gotcha" />
            <Button disabled={sending}>Send</Button>
            {notice ?
              <Notice type={notice.type}>{notice.message}</Notice> : ''}
          </Col>
        </Row>
      </Section>
    </Wrapper>
  );
};

Contact.propTypes = {
  overlapRef: PropTypes.object.isRequired
};

export default Contact;