
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import useThrottle from '~hooks/useThrottle';
import useDebounce from '~hooks/useDebounce';

const Placeholder = styled.div` 
  ${props => props.height && css`
    height: ${props => props.height}px;
  `};
`

const Wrapper = styled.div` 
  top: 0;
  left: 0;
  width: 100%;  
  position: ${props => props.position || 'static'};

  ${props => props.position === 'absolute' && css` 
    top: ${props => props.top}px;
  `}

  ${props => props.pinned && css`
    padding-top: ${props => props.offset || 0}px;
  `}
`

const Sticky = ({ className, target, offset, onChange, children }) => {

  const [state, setState] = useState({ position: 'static' });
  const placeholder = useRef();
  const root = useRef();

  const handleScroll = useThrottle(
    (root, state, offset) => {
      let update = getAbsoluteState(state) ||
        getStaticState(state) ||
        getFixedState(state, root, offset);
      if (update) {
        setState(update);
      }
    }, 100);

  const handleResize = useDebounce(
    (root, placeholder, target, offset) => {
      setState((current) => {
        let bounds = getBounds(root, placeholder, target, offset, current.pinned);
        return { ...current, ...bounds }
      });
    }, 500);

  useEffect(() => {
    const onScroll = () => { handleScroll(root, state, offset) };
    const onResize = () => { handleResize(root, placeholder, target, offset) };
    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('resize', onResize, false);
    return () => {
      handleScroll.cancel();
      handleResize.cancel();
      window.removeEventListener('scroll', onScroll, false);
      window.removeEventListener('resize', onResize, false);
    };
  }, [target, state, offset, handleScroll, handleResize]);

  useEffect(() => {
    if (typeof onChange === 'function') {
      onChange(root, state);
    }
  }, [onChange, state]);

  useEffect(() => {
    setState((current) => {
      let bounds = getBounds(root, placeholder, target, offset, current.pinned);
      return { ...current, ...bounds };
    });
  }, [target, offset]);

  return (
    <React.Fragment>
      <Placeholder ref={placeholder} height={state.height}>
        <Wrapper className={[className, state.pinned && 'pinned']} ref={root} offset={offset} position={state.position} top={state.lower} pinned={state.pinned}>{children}</Wrapper>
      </Placeholder>
    </React.Fragment>
  );
};
/**
 * @param {Object} root 
 * @param {Object} placeholder
 * @param {Object} target 
 * @param {Number} offset 
 * @param {Boolean} pinned
 * @returns {Object}
 */
const getBounds = (root, placeholder, target, offset, pinned) => {
  let bounds = (root.current && placeholder.current && target.current) ?
    {
      height: root.current.offsetHeight - (pinned ? offset : 0),
      upper: placeholder.current.offsetTop - offset,
      lower: (
        target.current.offsetTop +
        target.current.offsetHeight -
        placeholder.current.offsetHeight
      )
    } : {};
  return bounds;
};

/**
 * @param {String} state 
 * @param {Object} root
 * @param {Number} offset
 * @returns {Object}|false
 */
const getFixedState = (state, root, offset) => {
  if (state.position === 'absolute' && state.lower > window.scrollY) {
    return { ...state, pinned: true, position: 'fixed' };
  }
  if (state.position === 'static' && (root.current && root.current.getBoundingClientRect().top - offset < 0)) {
    return { ...state, pinned: true, position: 'fixed' };
  }
  return false;
};

/**
 * @param {String} state 
 * @returns {Object}|false
 */
const getAbsoluteState = (state) => {
  if (state.position !== 'absolute' && state.lower < window.scrollY) {
    return { ...state, pinned: true, position: 'absolute' };
  }
  return false;
};

/**
 * @param {String} state
 * @returns {Object}|false
 */
const getStaticState = (state) => {
  if (state.position === 'fixed' && state.upper > window.scrollY) {
    return { ...state, pinned: false, position: 'static' };
  }
  return false;
};

Sticky.defaultProps = {
  target: {},
  offset: 0
};

Sticky.propTypes = {
  target: PropTypes.object.isRequired,
  offset: PropTypes.number
};

export default Sticky;