import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { isField, getFirstFieldProps } from './Fields';
import { labelMixin } from '~styles/mixins';

const Wrapper = styled.div`
  font-size: ${props => props.theme.type.sizeForm || 'inherit'};
`;

const ElementBlur = css`
  opacity: 1;
`

const ElementFocus = css`
  opacity:.6;
  font-size: 80%;
`

const Element = styled.label`
  transition: all .1s ease;
  ${labelMixin};
  ${props => props.focus ?
    ElementFocus :
    ElementBlur
  };
`;

const Label = ({ children, className, value }) => {

  const {
    id,
    name,
    required
  } = getFirstFieldProps(React.Children.toArray(children));

  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    if (!focus) {
      setFocus(true);
    }
  };

  const handleBlur = (event) => {
    if (focus) {
      setFocus(event.target.value ? true : false);
    }
  };

  return (
    <Wrapper onFocus={handleFocus} onBlur={handleBlur}>
      <Element className={className} htmlFor={id || name} required={required} focus={focus}>{value}</Element>
      {React.Children.map(children, (child) =>
        React.isValidElement(child) && isField(child.type) ?
          React.cloneElement(child, { id: child.props.id || child.props.name }) :
          child
      )}
    </Wrapper>
  )
};

Label.propTypes = {
  value: PropTypes.string.isRequired
};

export default Label;
