
import React, { useRef, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { withTheme } from 'styled-components';
import useMatchMedia from '~hooks/useMatchMedia';
import { Row, Col } from '~components/Grid/Grid';
import { H2, H3, H4 } from '~components/Heading/Headings';
import { ImageCard } from '~components/Card/Card';
import FlightPath from '~components/FlightPath/FlightPath';
import Timeline from '~components/Timeline/Timeline';
import Sticky from '~components/Sticky/Sticky';
import { contentLayoutMixin } from '~styles/mixins';
import Section from './Section';

const Wrapper = styled.div`
  color: ${props => props.theme.colors.font};
  background-color: ${props => props.theme.colors.background};
  min-height: 100vh;
  padding: 8rem 0;
`

const Paragraph = styled.p`
  margin-bottom: ${props => props.margin || '2rem'};
`
const List = styled.ul`
  padding: 0;
  list-style: none;
  margin: ${props => props.margin || 0} 0;
  > * {
    margin-bottom: ${props => props.spacing || 0};
  }
`

const Image = styled.img`
  height: ${props => props.height || 'auto'};
  width: ${props => props.width || 'auto'};
`

const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`

const Plane = styled(FlightPath)`
  height: 15px;
`

const Maps = styled.nav`
  display: flex;
  flex-wrap: none;
  justify-content: space-evenly;
  padding: 5rem 0;
`

const Years = styled.div`
  font-weight: 300;
  font-size: 4rem;
  line-height: 4rem;
  margin-bottom: 1rem;
`

const Location = styled.span`
  font-size: 1.4rem;
  font-weight: 300;
  display: block;
  letter-spacing: .1rem;
  text-transform: uppercase;
`

const Navigation = styled(Sticky)`
  background-color: white;
  &.pinned {
    ${contentLayoutMixin};
  }
`

const Occupations = styled.div``;

const Occupation = styled(Row)` 
  margin-top: 5rem;
`;

const Experience = ({ overlapRef, theme }) => {

  const { section } = useStaticQuery(graphql`
    query {
      section: sectionYaml(title: {eq: "Experience"}) {
        ...ExperienceSection
      }
    }
  `);

  const {
    title,
    description,
    locations,
    occupations
  } = section;

  const ref = useRef();
  const destinations = useRef({});

  const [dates, setDates] = useState([]);
  const [offset, setOffset] = useState(0);
  const [wideLayout, setWideLayout] = useState(false);
  const [occupation, setOccupation] = useState(() => {
    if (occupations) {
      return occupations[0];
    }
  });

  useEffect(() => {
    if (section) {
      let dates = (section.occupations || [])
        .map((occupation) => {
          return {
            key: occupation.key,
            from: occupation.years.from,
            to: occupation.years.to
          };
        });
      setDates(dates);
    }
  }, [section]);

  useMatchMedia(`(min-width: ${theme.devices.sm})`,
    useCallback((event) => {
      setWideLayout(event.matches);
    }, []));

  const addDestination = (key, node) => {
    destinations.current[key] = node;
  };

  const updateOffset = useCallback((ref) => {
    if (ref.current) {
      setOffset(ref.current.offsetHeight + (ref.current.classList.contains('pinned') ? 0 : 110));
    }
  }, []);

  const setOccupationFromKey = useCallback((key) => {
    if (section) {
      setOccupation((current) => {
        let occupation = (section.occupations || [])
          .filter((item) => item.key === key && (!current || item.key !== current.key))[0];
        return occupation || current;
      });
    }
  }, [section]);

  const setOccupationFromLocation = useCallback((location) => {
    if (section) {
      setOccupation((current) => {
        let occupation = (section.occupations || [])
          .filter((item) => item.location.code === location.code && (!current || item.key !== current.key))[0];
        return occupation || current;
      });
    }
  }, [section]);

  return (
    <Wrapper ref={overlapRef} id="experience">

      <Section>
        <Row>
          <Col xs={12} sm={6} md={5} lg={4}>
            <H2>{title}</H2>
            <Paragraph margin="4rem">{description}</Paragraph>
          </Col>
        </Row>

        {wideLayout ?
          <Navigation target={ref} offset={110} onChange={updateOffset}>
            <Timeline dates={dates} date={occupation && occupation.key} offset={offset} onChange={setOccupationFromKey} />
            <Maps>
              {locations.map((location) =>
                <Button key={location.code} onClick={() => { setOccupationFromLocation(location); }}>
                  <Image ref={(node) => addDestination(location.code, node)} src={location.map.publicURL} alt={location.name} width="10rem" height="10rem" />
                </Button>
              )}
            </Maps>
            <Plane destinations={destinations.current} destination={occupation && occupation.location.code} duration={3} minimum={50} />
          </Navigation> : ''}

        <Occupations ref={ref}>
          {occupations.map((occupation) =>
            <Occupation id={occupation.key} key={occupation.key} align="flex-start">
              <Col xs={12} sm={5} md={4} lg={3}>
                <Years>
                  <time dateTime={occupation.years.from}>{occupation.years.from}</time>{occupation.years.from !== occupation.years.to ? <React.Fragment> - <time dateTime={occupation.years.to}>{occupation.years.to}</time></React.Fragment> : ''}
                </Years>
                <H3 margin="0">{occupation.name}</H3>
                <Location>
                  {occupation.location.name}
                </Location>
              </Col>
              <Col xs={12} sm={7} md={8} lg={5}>
                <H4 fontSize="1.6rem">{occupation.role}</H4>
                {occupation.content.map((content, key) =>
                  <Paragraph key={key} margin="4rem">{content}</Paragraph>
                )}
                {occupation.highlights.length ?
                  <List as="ul" spacing="1rem">
                    {occupation.highlights.map((highlight, key) =>
                      <ImageCard as="li" key={key} image={highlight.icon.publicURL} imageAlt={highlight.title} imageHeight="3rem" imageWidth="3rem" align="center">
                        <H4 transform="none" margin=".5rem">{highlight.title}</H4>
                        <Paragraph margin="0rem">{highlight.note}</Paragraph>
                      </ImageCard>
                    )}
                  </List> : ''
                }
              </Col>
            </Occupation>
          )}
        </Occupations>

      </Section>
    </Wrapper>
  );
};

Experience.propTypes = {
  overlapRef: PropTypes.object.isRequired
};

export default withTheme(Experience);