import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import Placeholder from '~components/Placeholder/Placeholder';
import HeaderCollapsed from './HeaderCollapsed';
import HeaderExpanded from './HeaderExpanded';

const Header = ({ navigation, transitions, themes }) => {

  const [
    ref,
    isExpanded
  ] = useInView({
    threshold: [0, 1],
    initialInView: true,
    rootMargin: '-120px 0px 0px 0px'
  });

  return (
    <React.Fragment>
      <Placeholder invisible={!isExpanded} ref={ref}>
        <HeaderExpanded navigation={navigation} />
      </Placeholder>
      {!isExpanded ?
        <HeaderCollapsed navigation={navigation} transitions={transitions} themes={themes} />
        : ''}
    </React.Fragment>
  )
};

Header.defaultProps = {
  navigation: [],
  transitions: []
};

Header.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired
    })
  ),
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.object.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  )
};

export default Header;
