import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { inputMixin } from '~styles/mixins';

const Element = styled.textarea`
  ${inputMixin}
`;

const TextArea = ({ children, className, name, id, rows, required, theme }) => {
  return (
    <Element className={className} name={name} id={id} rows={rows} required={required}>
      {children}
    </Element>
  )
};

TextArea.defaultProps = {
  rows: 10,
  required: false
};

TextArea.propTypes = {
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  id: PropTypes.string
};

export default TextArea;
