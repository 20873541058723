import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Element = styled.button`
  cursor: pointer;
  margin: 0 auto;
  padding: 1rem 2rem;
  width: 100%;
  border-radius: .2rem;
  border: 1px solid transparent;
  display: block;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.colors.buttonFont || 'rgb(255,255,255)'};
  background: ${props => props.theme.colors.button || 'rgb(0,0,0)'};

  &[disabled] {
    background: ${props => props.theme.colors.buttonDisabled || 'rgb(100,100,100)'};
  }
`;

const Button = ({ children, className, type, disabled }) => {
  return <Element className={className} type={type} disabled={disabled}>{children}</Element>;
};

Button.defaultProps = {
  type: 'submit',
  disabled: false
};

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool
};

export default Button;
