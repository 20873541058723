import { useEffect, useRef } from 'react';

const useMatchMedia = (query, callback) => {
  const ref = useRef();
  useEffect(() => {
    if (!ref.current) {
      ref.current = window.matchMedia(query);
      callback(ref.current);
    }
    (ref.current.addEventListener === 'function') ?
      ref.current.addEventListener('change', callback) :
      ref.current.addListener(callback);
    return () => {
      (ref.current.removeEventListener === 'function') ?
        ref.current.removeEventListener('change', callback) :
        ref.current.removeListener(callback);
    };
  }, [query, callback]);
};

export default useMatchMedia;