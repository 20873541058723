
/**
 * @var {Object}
 */
const dataProviders = {
  default: (form) => {
    return new FormData(form);
  },
  'application/json': (form) => {
    return JSON.stringify(
      Object.fromEntries((new FormData(form)).entries())
    );
  }
};

/**
 * @param {String} encType
 * @param {Element} form 
 * @return {Object}
 */
export const getFormData = (encType, form) => {
  if (!form) {
    return {};
  }

  const provider = dataProviders[encType];

  if (provider) {
    return provider(form);
  }

  return dataProviders['default'](form);
};