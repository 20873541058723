import React from 'react';

export const FormContext = React.createContext();

export const FormContextProvider = ({ children, form }) => {
  return (
    <FormContext.Provider value={form}>
      {children}
    </FormContext.Provider>
  )
};
