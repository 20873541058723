
import React from 'react';
import Heading from './Heading';

const H2 = ({ children, ...props }) => {
  return (
    <Heading as="h2" {...props}>{children}</Heading>
  );
};

H2.defaultProps = {
  fontSize: '3rem',
  lineHeight: '3.6rem'
};

export default H2;