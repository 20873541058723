import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import Scroll from 'react-scroll';
import Navigation from '~components/Navigation/Navigation';
import useIsOverlapping from '~hooks/useIsOverlapping';

import { fadeInAnimation } from '~styles/animations';

const Header = styled.header`
  top: 0;
  position: fixed;
  z-index: 100;
  width: 100%;
  background-color: transparent;
  color: ${props => props.theme.colors.font};

  @media screen and (min-width: ${props => props.theme.devices.md}) {
    animation-name: ${fadeInAnimation};
    animation-duration: .2s; 
    animation-timing-function: ease-out; 
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  max-width: ${props => props.theme.layout.maxWidth};
  padding: 2rem ${props => props.theme.layout.contentPaddingSm};
  margin: 0 auto;

  @media screen and (min-width: ${props => props.theme.devices.xl}) {
    padding: 2rem ${props => props.theme.layout.contentPaddingLg};
  }
`

const Title = styled.a`
  margin: 0;
  margin-left: 6rem;
  font-size: 1.8rem;
  font-weight: 200;
  line-height: 2.2rem;
  text-transform: uppercase;

  span {
    font-size: 2.8rem;
    font-weight: 500;
    display: block;
  }

  &,
  &:link,
  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }

  @media screen and (min-width: ${props => props.theme.devices.md}) {
      margin: 0;
  }

  @media screen and (min-width: ${props => props.theme.devices.lg}) {
    font-size: 2.4rem;
    line-height: 3.4rem;
    span {
      font-size: 4.5rem;
    }
  }
`

const HeaderCollapsed = ({ navigation, transitions, themes }) => {

  const [targets, setTargets] = useState([]);

  const onOverlap = useCallback((overlap, target, root) => {
    let transition;
    for (var i = 0; i < transitions.length; i++) {
      transition = transitions[i];
      if (transition.ref === target) {
        let colors = getColors(
          themes[transition.from],
          themes[transition.to],
          overlap
        );

        if (root.current) {
          root.current.style.color = colors.font;
          root.current.style.backgroundColor = colors.background;
        }
        break;
      }
    }
  }, [transitions, themes]);

  const scrollToTop = (event) => {
    event.preventDefault();
    Scroll.animateScroll.scrollTo(0, {
      smooth: 'easeInOutQuart'
    });
  };

  useEffect(() => {
    setTargets(() => transitions.map((transition) => transition.ref));
  }, [transitions]);

  const { ref } = useIsOverlapping(targets, onOverlap);

  return (
    <Header ref={ref}>
      <Wrapper>
        <Title href="#about" title="Jon Wenmoth, Software Developer" onClick={scrollToTop}>
          Jon <span>Wenmoth</span>
        </Title>
        <Navigation items={navigation} direction="row" />
      </Wrapper>
    </Header>
  );
};

/**
 * Get the background and text colors
 * of the header based on what section
 * the header is currently overlapping.
 * 
 * @param {Object} from 
 * @param {Object} to
 * @param {Number} threshold
 * 
 * @return {Object}
 */
const getColors = (from, to, threshold) => {
  if (threshold === 0) {
    return from.colors;
  }

  if (threshold === 1) {
    return to.colors;
  }

  return {
    background: 'transparent',
    font: getMixedColor(from.colors.font, to.colors.font, threshold),
  };
};

/**
 * Mix 2 colors together and return
 * the RGB color string.
 * 
 * @param {String} from 
 * @param {String} to 
 * @param {Number} ratio 
 * 
 * @return {String}
 */
const getMixedColor = (from, to, ratio) => {
  const color1 = from
    .substring(4, from.length - 1)
    .replace(/ /g, '')
    .split(',');

  const color2 = to
    .substring(4, to.length - 1)
    .replace(/ /g, '')
    .split(',');

  const r = Math.ceil(Number((color1[0]) * (1 - ratio).toFixed(1))) + Math.round(Number(color2[0]) * ratio);
  const g = Math.ceil(Number((color1[1]) * (1 - ratio).toFixed(1))) + Math.round(Number(color2[1]) * ratio);
  const b = Math.ceil(Number((color1[2]) * (1 - ratio).toFixed(1))) + Math.round(Number(color2[2]) * ratio);

  return `rgba(${r},${g},${b},${color1[3] || 1})`;
};

HeaderCollapsed.defaultProps = {
  navigation: [],
  transitions: [],
  themes: {}
};

HeaderCollapsed.propTypes = {
  navigation: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      location: PropTypes.string.isRequired
    })
  ),
  transitions: PropTypes.arrayOf(
    PropTypes.shape({
      ref: PropTypes.object.isRequired,
      from: PropTypes.string.isRequired,
      to: PropTypes.string.isRequired,
    })
  )
};

export default withTheme(HeaderCollapsed);
