import { useRef, useEffect } from 'react';
import { getElementOverlap } from '~helpers/dom';
import useThrottle from '~hooks/useThrottle';

const thresholds = [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1];

const useIsOverlapping = (targets, onOverlap) => {

  const ref = useRef();
  const overlapping = useRef();

  const checkOverlap = useThrottle(
    (root, targets, overlapping, onOverlap) => {
      let target = getNearestTarget(targets, root),
        current = overlapping.current;
      if (target && (!current || hasChanged(current, target))) {
        overlapping.current = target;
        onOverlap(target.threshold, target.ref, root);
      }
    }, 60);

  useEffect(() => {
    const onScroll = () => {
      checkOverlap(ref, targets, overlapping, onOverlap);
    };
    onScroll();
    window.addEventListener('scroll', onScroll, false);
    return () => {
      window.removeEventListener('scroll', onScroll, false);
    };
  }, [targets, onOverlap, checkOverlap]);

  return { ref };
};

/**
 * @param {Object} current 
 * @param {Object} target 
 * @returns Boolean
 */
const hasChanged = (current, target) => {

  if (current.ref === target.ref &&
    current.threshold === target.threshold) {
    return false;
  }

  let isThreshold = false;
  thresholds.forEach((value) => {
    if (value === target.threshold) {
      isThreshold = true;
    }
  });

  return isThreshold;
};

/**
 * @param {Array} targets 
 * @param {Object} root 
 * @returns {Object}
 */
const getNearestTarget = (targets, root) => {
  let nearest;

  targets.forEach((target) => {

    let overlap = getElementOverlap(
      root.current,
      target.current
    );

    if (overlap > 0 && (!nearest || overlap < nearest.overlap)) {
      nearest = {
        ref: target,
        overlap: overlap,
        threshold: Math.min(1, overlap)
      };
    }
  });

  return nearest;
};

export default useIsOverlapping;