import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import styled, { css, withTheme } from 'styled-components';
import { Row, Col } from '~components/Grid/Grid';
import { H2, H3 } from '~components/Heading/Headings';
import { ImageCard } from '~components/Card/Card';
import BaseSection from './Section';

const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.font};
  background-color: ${(props) => props.theme.colors.background};
  min-height: 100vh;
  padding: 8rem 0;
`;

const Section = styled(BaseSection)`
  &:not(:last-of-type) {
    border-bottom: 1px solid ${(props) => props.theme.colors.border};
  }
`;

const OffsetRow = styled(Row)`
  @media screen and (min-width: ${(props) => props.theme.devices.md}) {
    z-index: 20;
    position: relative;
    margin-bottom: -18rem;
  }
  @media screen and (min-width: ${(props) => props.theme.devices.lg}) {
    margin-bottom: -25rem;
  }
`;

const ToolItem = (props) => <ImageCard as="li" {...props} />;

const ToolList = styled.ul`
  padding: 0;
  list-style: none;
  font-size: 1.4rem;
  text-transform: uppercase;

  a {
    color: inherit;
  }
`;

const StackNav = styled.nav`
  font-weight: 500;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
`;

const StackItem = styled.a`
  padding: 0.5rem 1rem;
  margin: 0.5rem 0.1rem;
  border-radius: 0.2rem;
  color: inherit;

  ${(props) =>
    props.active
      ? css`
          background: ${(props) => props.theme.colors.font};
          color: ${(props) => props.theme.colors.background};
        `
      : ''}
`;

const StackImage = styled(GatsbyImage)`
  display: ${(props) => props.display || 'block'};
  z-index: 10;
  @media screen and (max-width: ${(props) => props.theme.devices.sm}) {
    padding-bottom: 168%;
  }
`;

const ProcessImage = styled(GatsbyImage)`
  @media screen and (max-width: ${(props) => props.theme.devices.lg}) {
    padding-bottom: 40%;
  }
`;

const PlansImage = styled(GatsbyImage)`
  max-width: 20rem;
  margin: 0 auto;
  margin-bottom: 5rem;
  @media screen and (max-width: ${(props) => props.theme.devices.md}) {
    display: none;
  }
`;

const Knowledge = ({ overlapRef, theme }) => {
  const { section } = useStaticQuery(graphql`
    query {
      section: sectionYaml(title: { eq: "Knowledge" }) {
        ...KnowledgeSection
      }
    }
  `);

  const { stacks, process, systems, tools } = section;

  const [stack, setStack] = useState(() => {
    if (stacks.stacks.length) {
      return stacks.stacks[0] || {};
    }
  });

  const getArtDirectedImage = (image, breakpoint) => {
    return withArtDirection(getImage(image.src), [
      {
        media: `(max-width: ${breakpoint})`,
        image: getImage(image.compact),
      },
    ]);
  };

  return (
    <Wrapper ref={overlapRef} id="knowledge">
      <Section>
        <Row last={true}>
          <Col xs={12} md={6} lg={4}>
            <H2>{process.title}</H2>
          </Col>
          <Col xs={12} md={6} lg={8}>
            {process.processes.map((process, key) => (
              <React.Fragment key={key}>
                <H3>{process.title}</H3>
                <p>{process.description}</p>
              </React.Fragment>
            ))}
          </Col>
        </Row>
      </Section>

      <Section>
        <Row last={true}>
          <Col xs={12} md={5} lg={4}>
            <H2>{systems.title}</H2>
            <p>{systems.description}</p>
          </Col>
          <Col xs={12} md={7} lg={8} xsOrder={1} mdOrder={-1}>
            <GatsbyImage
              image={getImage(systems.image.src)}
              alt={systems.image.alt}
            />
          </Col>
        </Row>
      </Section>

      <Section>
        <Row last={true} align="flex-start">
          <Col xs={12} sm={6} md={5} lg={4}>
            <H2>{tools.title}</H2>
            <p>{tools.description}</p>
          </Col>
          <Col xs={12} md={7} lg={8}>
            <Row as={ToolList} last={true}>
              {tools.tools.map((tool, key) => (
                <Col
                  key={key}
                  as={ToolItem}
                  xs={6}
                  lg={4}
                  image={tool.icon.publicURL}
                  imageAlt={tool.name}
                  imageWidth="3rem"
                  imageHeight="3rem"
                  align="center"
                >
                  <a href={tool.link} title={tool.name}>
                    {tool.name}
                  </a>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Section>
    </Wrapper>
  );
};

Knowledge.propTypes = {
  overlapRef: PropTypes.object.isRequired,
};

export default withTheme(Knowledge);
