
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Element = styled.div.attrs(props => ({
  fontSize: props.fontSize || '3.5rem',
  weight: props.weight || 400,
  margin: props.margin || '2rem',
  transform: props.transform || 'uppercase',
  lineHeight: props.lineHeight || 'inherit',
}))`
  font-size: ${props => props.fontSize};
  font-weight: ${props => props.weight};
  text-transform: ${props => props.transform};
  margin-bottom: ${props => props.margin};
  line-height: ${props => props.lineHeight};
`

const Heading = ({ children, as, className, fontSize, weight, margin, transform, lineHeight }) => {
  return (
    <Element
      as={as}
      className={className}
      fontSize={fontSize}
      weight={weight}
      margin={margin}
      transform={transform}
      lineHeight={lineHeight}
    >
      {children}
    </Element>
  );
};

Heading.defaultProps = {
  as: 'div',
  weight: 600,
  margin: '1.5rem',
  fontSize: '3.5rem',
  transform: 'uppercase',
  lineHeight: 'inherit'
};

Heading.propTypes = {
  fontSize: PropTypes.string,
  weight: PropTypes.number,
  margin: PropTypes.string,
  transform: PropTypes.string,
  lineHeight: PropTypes.string,
};

export default Heading;