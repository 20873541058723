
import React from 'react';
import styled from 'styled-components';
import { contentLayoutMixin } from '~styles/mixins';

const Element = styled.section`
  ${contentLayoutMixin}
  padding-top: 7rem;
  padding-bottom: 7rem;
`

const Section = React.forwardRef((props, ref) => {

  const {
    id,
    className,
    children
  } = props;

  return (
    <Element ref={ref} id={id} className={className}>
      {children}
    </Element>
  );
});

export default Section;