import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormContextProvider } from '~contexts/FormContextProvider';

const Element = styled.form`
  color: ${props => props.theme.colors.font || 'inherit'};
  border-color: ${props => props.theme.colors.border || 'inherit'};
  font-size: ${props => props.theme.type.sizeForm || 'inherit'};
  width: 100%;
`;

const Fieldset = styled.fieldset`
  border: none;
  &[disabled] {
    opacity: .5;
  }
`;

const Form = ({ children, className, action, name, method, encType, disabled, onSubmit, onReset }) => {

  const ref = useRef();

  const handleSubmit = (event) => {
    if (typeof onSubmit === 'function') {
      onSubmit(event);
    }
  };

  const handleReset = (event) => {
    if (typeof onReset === 'function') {
      onReset(event);
    }
  };

  return (
    <FormContextProvider form={ref}>
      <Element ref={ref} className={className} action={action} name={name} method={method} encType={encType} onSubmit={handleSubmit} onReset={handleReset}>
        <Fieldset disabled={disabled}>
          {children}
        </Fieldset>
      </Element>
    </FormContextProvider>
  )
};

Form.defaultProps = {
  method: 'POST',
  disabled: false
};

Form.propTypes = {
  action: PropTypes.string.isRequired,
  name: PropTypes.string,
  method: PropTypes.oneOf([
    'POST',
    'GET'
  ]),
  encType: PropTypes.oneOf([
    'application/x-www-form-urlencoded',
    'multipart/form-data',
    'text/plain'
  ]),
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  onReset: PropTypes.func
};

export default Form;
