import 'intersection-observer';
import React, { useRef } from 'react';
import Layout from '~components/Layout/Default';
import { ThemeContextProvider } from '~contexts/ThemeContextProvider';
import {  Contact, Knowledge } from '~components/Section/Sections';

const IndexPage = () => {

  const refKnowledge = useRef();
  const refContact = useRef();

  const transitions = [
    { ref: refKnowledge, from: 'default', to: 'grey' },
    { ref: refContact, from: 'grey', to: 'blue' }
  ];

  return (
    <Layout transitions={transitions}>
      <ThemeContextProvider name="grey">
        <Knowledge overlapRef={refKnowledge} />
      </ThemeContextProvider>
      <ThemeContextProvider name="blue">
        <Contact overlapRef={refContact} />
      </ThemeContextProvider>
    </Layout>
  )
};


export default IndexPage;
